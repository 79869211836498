<template>
	<div class="flex-col page">
		<Mapview  @address="formattedAddress"></Mapview>
		<div class="flex-col section_1">
			<div class="flex-col text-wrapper">
				<span class="text_1">{{ title }}</span>
			</div>
		</div>
		<div class="flex-col group_1">
			<div class="flex-col">
				<span class="text_12">维保工单</span>
			</div>
			<div class="list" :key="i" v-for="(title, i) in list">
				<div class="list-item flex-row">
					<span class="text_13">{{ title.title }}</span>
					<span
						v-if="!title.time"
						:class="['text_15', { look: title.title == '维保设备' }, { red: title.title == '维保任务内容' }]"
						@click="allTask(title.name)"
						>{{ title.name }}</span
					>
					<van-count-down :time="title.time" v-if="title.time && title.time != ''" />
				</div>
				<div
					style="width: 100vw; display: block"
					class="flex-row equal-division"
					v-if="title.title == '完工签到(请按要求上传照片)'"
				>
					<van-uploader
						accept="video/*,image/*"
						:after-read="afterRead"
						v-model="fileList"
						multiple
						:max-count="8"
						result-type="file"
					/>
				</div>
				<textarea
					v-if="title.title == '维修说明'"
					id="textarea"
					placeholder="请输入维修说明"
					cols="10"
					rows="5"
				></textarea>
				<input
					v-if="title.title == '本次纳入零件'"
					type="text"
					id="thisParts"
					placeholder="请输入本次纳入零件"
				/>
				<input
					v-if="title.title == '下次带来零件'"
					type="text"
					id="nextParts"
					placeholder="请输入带来零件"
				/>
			</div>
			<div class="button flex-col items-center text-wrapper_1" @click="finish()">
				<span>完成工单</span>
			</div>
		</div>
	</div>
</template>

<script>
import { detail, successOrder } from "@/api/Maintenance/Maintenance/all";
import { Toast } from "vant";
import { uploadOrderFile } from "@/api/file";
import Mapview from "../../MapContainer"

export default {
	data() {
		return {
			title: "工单详情",
			ReceiveTime: "", //创建时间
			OrderTime: "", //接单时间
			elevator_id: "",
			list: [
				{ title: "维保设备", name: "点击查看" },
				{ title: "维保类型", name: "" },
				{ title: "维保任务内容", name: "点击操作" },
				{ title: "维修说明" },
				{ title: "本次纳入零件" },
				{ title: "下次带来零件" },
				{ title: "完工签到(请按要求上传照片)",},
				{ title: "工单倒计时", time: 30 * 60 * 60 * 1000 },
			],
			fileList: [],
			formatted_address:"",//当前定位地址
		};
	},
	components: {
		Mapview
	},
	methods: {
		afterRead(file, row) {
			file.status = "uploading";
			file.message = "上传中...";
			var formData = new FormData();
			formData.append("file", file.file);
			formData.append("id", this.$route.params.id);
			formData.append("type", "maintenance");
			formData.append("formatted_address",this.formatted_address);

			uploadOrderFile(formData).then((res) => {
				console.log(res);
				if (res.data.Result === 200) {
					file.status = "done";
					file.message = "上传成功";
					this.fileList[row.index].url = res.data.Data;
				} else {
					file.status = "failed";
					file.message = res.data.Message;
				}
			});
		},
		allTask(name) {
			if (name == "点击查看") {
				this.$router.push("/maintenance/elevator/" + this.elevator_id);
			} else if (name == "点击操作") {
				this.$router.push("/maintenance/maintenance/item-list/" + this.$route.params.id);
			}
		},
		finish() {
			console.log({
				id: this.$route.params.id,
				item_id: sessionStorage.getItem("Item" + this.$route.params.id),
				MaintenanceResult: textarea.value,
				MaintenanceProcessPhotosOfVideo: this.fileList, //图片或视频
				thisParts: thisParts.value, //本次纳入零件
				nextParts: nextParts.value,  //下次带来零件
			});
			//点击完成
			successOrder({
				id: this.$route.params.id,
				item_id: sessionStorage.getItem("Item" + this.$route.params.id),
				MaintenanceResult: textarea.value,
				MaintenanceProcessPhotosOfVideo: this.fileList,
				thisParts: thisParts.value, 
				nextParts: nextParts.value, 
			}).then((res) => {
				// console.log(res);
				if (res.data.Result === 200) {
					Toast("任务完成");
					this.$router.push("/maintenance/index");
					sessionStorage.removeItem("Item");
				} else {
					Toast(res.data.Message);
				}
			});
		},
		formattedAddress(f){
			this.formatted_address = f
		}
	},
	mounted() {
		 
		detail({ id: String(this.$route.params.id) }).then((res) => {
			console.log(res);
			this.elevator_id = res.data.Data.elevator_id;
			this.ReceiveTime = res.data.Data.ReceiveTime;
			this.OrderTime = res.data.Data.OrderTime;
			this.list[1].name = res.data.Data.Type;
			this.list[5].time = Number(res.data.Data.ReceivedTimeout);
		});
	},
};
</script>

<style scoped>
@import "../../../assets/common.css";

input{
	width: 100%;
	border: none;
	font-size: 0.75rem;
	color: rgb(179, 179, 178);
	/* margin-bottom: 0.75rem; */
	background: #f8f9fd;
	border-radius: 0.4rem;
	margin-top: 0.8rem;
	box-sizing: border-box;
	padding: 0.6rem;
}

input::placeholder {
   font-weight: 400;
   color: rgb(179, 179, 178);
}

.button {
	color: rgb(255, 255, 255);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	padding: 1.06rem 0;
	background-image: url("../../../assets/buttonbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.list-item {
	/* border-bottom: solid 0.063rem rgb(243, 249, 244); */
}
.left-group {
	line-height: 0.81rem;
	text-align: center;
	width: 3.78rem;
	height: 1.63rem;
}
.text_13 {
	color: rgb(179, 179, 178);
	font-size: 0.75rem;
	line-height: 2rem;
	white-space: nowrap;
	display: inline-block;
	width: 5rem;
}
.text_15 {
	margin-left: 3.44rem;
	color: rgb(101, 102, 101);
	font-size: 0.88rem;
	line-height: 2rem;
	white-space: nowrap;
}
.text_6 {
	color: rgb(153, 153, 153);
	font-size: 0.69rem;
	line-height: 0.81rem;
}
.text_8 {
	color: rgb(153, 153, 153);
	font-size: 0.69rem;
	line-height: 0.81rem;
}
.page {
	padding-bottom: 1.56rem;
	background-color: rgb(255, 255, 255);
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
.section_1 {
	padding: 0 0 1.69rem;
}
.group_1 {
	margin-top: -2.56rem;
	padding: 1.69rem 1.25rem 0;
	background: #fff;
	border-radius: 1rem;
}
.section_2 {
	padding: 0.88rem 0.81rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	background-color: rgb(237, 237, 237);
}
.text-wrapper {
	padding: 1.88rem 0 3.44rem;
	color: rgb(255, 255, 255);
	font-size: 1.5rem;
	line-height: 1.44rem;
	white-space: nowrap;
	background-image: url(/img/imgbg1_03.7f7ef7cb.png);
	background-size: cover;
	background-repeat: no-repeat;
}
.list {
	margin-top: 1.13rem;
	border-bottom: solid 0.063rem rgb(243, 249, 244);
}
.text-wrapper_1 {
	margin-top: 1.88rem;
}
.image_1 {
	margin-right: 0.25rem;
	align-self: center;
	width: 1.13rem;
	height: 0.25rem;
}
.text_1 {
	margin-left: 1.5rem;
}
.group_3 {
	padding: 0 0.97rem 0.5rem 1.06rem;
}
.group_6 {
	padding: 0.44rem 0;
	position: relative;
}
.group_7 {
	margin-top: 0.56rem;
	padding: 0 0.94rem;
}
.text_12 {
	margin-top: 0.94rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
}
.image {
	margin-bottom: 0.13rem;
	width: 0.81rem;
	height: 0.81rem;
}
.text {
	margin-left: 0.69rem;
}
.text_5 {
	color: rgb(153, 153, 153);
	font-size: 0.69rem;
	line-height: 0.75rem;
	white-space: nowrap;
}
.divider {
	margin-left: 1.44rem;
	margin-right: 1.19rem;
	height: 0.063rem;
	border: solid 0.063rem rgb(153, 153, 153);
}
.image_2 {
	width: 0.88rem;
	height: 0.88rem;
	position: absolute;
	left: 0.94rem;
	top: 0.063rem;
}
.image_3 {
	width: 0.88rem;
	height: 0.88rem;
	position: absolute;
	left: 7.38rem;
	top: 0.063rem;
}
.image_4 {
	width: 0.88rem;
	height: 0.88rem;
	position: absolute;
	left: 12.63rem;
	top: 0;
}
.image_5 {
	width: 0.88rem;
	height: 0.88rem;
	position: absolute;
	right: 1rem;
	top: 0.063rem;
}
.view_1 {
	margin-left: 1.41rem;
}
.group_5 {
	color: rgb(153, 153, 153);
	font-size: 0.69rem;
	line-height: 0.69rem;
	white-space: nowrap;
}
.text_4 {
	margin-left: 3.53rem;
	color: rgb(62, 97, 164);
	font-size: 0.69rem;
	line-height: 0.69rem;
	white-space: nowrap;
}
.text_3 {
	margin-left: 2.97rem;
}
.look {
	color: #005ddb;
}
.red {
	color: #ffba41;
}

.equal-division-item_1 {
	flex: 1 1 5.38rem;
	padding: 0.31rem 0.25rem 0.31rem 0.31rem;
}
.image_8 {
	width: 4.81rem;
	height: 4.81rem;
}
.text_41 {
	display: block;
	width: 100%;
	text-align: center;
	margin-right: 0.13rem;
	margin-top: 0.56rem;
}
.equal-division {
	margin-top: 0.25rem;
	color: rgb(0, 83, 215);
	font-size: 0.75rem;
	line-height: 0.75rem;
	white-space: nowrap;
}
#textarea {
	width: 100%;
	color: rgb(179, 179, 178);
	background: #f8f9fd;
	border: none;
	border-radius: 0.4rem;
	margin-top: 0.8rem;
	box-sizing: border-box;
	padding: 0.6rem;
}
.van-count-down {
	color: #323233;
	font-size: 14px;
	line-height: 2.1rem;
	margin-left: 3.44rem;
}
.text_11 {
	display: block;
	text-align: center;
}
</style>
